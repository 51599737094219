import axios from 'axios';

// @ts-ignore
export const instance = axios.create({
  baseURL: 'https://upload.uploadcare.com/',
});

type BodyType = {
  UPLOADCARE_PUB_KEY: string;
  UPLOADCARE_STORE: string;
  file: File;
  file_name: string;
};

export const uploadPhoto = async <T>(body: BodyType): Promise<T> => {
  const result = await instance.post('base/?jsonerrors=1', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return result.data;
};

export const getInfoPhoto = async <T>(pub_key: string, file_id: string): Promise<T> => {
  const result = await instance.get(`info/?jsonerrors=1&pub_key=${pub_key}&file_id=${file_id}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return result.data;
};

export const fetchToken = () =>
  fetch('https://dev-i88utbyp.us.auth0.com/oauth/token', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      Cookie:
        'did=s%3Av0%3A68ad6510-2fba-11ed-a43f-0d825b60e589.azCkDIxPNauijrjjfxqgAPZp7nrixJyeUo3MFFUVQpA; did_compat=s%3Av0%3A68ad6510-2fba-11ed-a43f-0d825b60e589.azCkDIxPNauijrjjfxqgAPZp7nrixJyeUo3MFFUVQpA',
    },
    body: JSON.stringify({
      client_id: 'Md191uCRfcv1CHleKYcCa1bXZr4qxulR',
      client_secret: '9B5Ne07xTYn5JW-k6OHp1yl9YIU1T_u-wjCuB3HZr-h0pVRMIfzJe7npj0EjM0rX',
      audience: 'https://auth0-jwt-authorizer',
      grant_type: 'client_credentials',
    }),
  });
