import Cookies from 'js-cookie';

const CLIENT_ID = '5349be48-2c11-4bba-8a11-96ceb025f9c6';

export const getAccessToken = async (forceRefresh = false): Promise<string> => {
  const TOKEN_KEY = 'access_token';
  const AUTH_API_URL = 'https://legitgrails-api.com/1/authorize';
  const CLIENT_ID = 'af2ac997-ab74-4ee9-a90b-754d8c15dedf';
  const CLIENT_SECRET = '11485fdd-501e-4d02-8ded-7d1fbe63d85e';

  if (!forceRefresh) {
    const storedToken = Cookies.get(TOKEN_KEY);
    if (storedToken) {
      return storedToken;
    }
  }

  try {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'client-id': CLIENT_ID,
        'client-secret': CLIENT_SECRET,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to authenticate');
    }

    const data = await response.json();
    const token = data.access_token;

    // Store token in cookies
    Cookies.set(TOKEN_KEY, token, { secure: true, sameSite: 'strict' });

    return token;
  } catch (error) {
    console.error('Error fetching access token:', error);
    throw error;
  }
};

export const fetchGetPhotoList = async <T>(token: string, testId: string, admin?: string): Promise<T> => {
  const url = `https://ymo8ktxz5m.execute-api.eu-west-2.amazonaws.com/staging/photos/resubmit?id=${testId}&client_id=5349be48-2c11-4bba-8a11-96ceb025f9c6${
    admin ? `&admin=${admin}` : ''
  }`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const body = await response.json();

  return body;
};

export const postPhotos = async <T>(
  token: string,
  bodyRequest: { id: string; photos: Array<{ index: string; url: string }> },
  admin?: string,
): Promise<T> => {
  const newBody = admin ? { ...bodyRequest, admin } : bodyRequest;

  const response = await fetch('https://9qmmmgbfl0.execute-api.eu-west-2.amazonaws.com/1/photo/reupload', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ client_id: CLIENT_ID, ...newBody }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const body = await response.json();

  return body;
};

export const rejectOrder = async <T>(token: string, uniqueCode: string): Promise<T> => {
  const response = await fetch(
    `https://9qmmmgbfl0.execute-api.eu-west-2.amazonaws.com//staging/order?client-id=${CLIENT_ID}`,
    {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: uniqueCode,
        outcome: 'canceled',
        user: 'photo_reuploader',
        outcome_reason: 'FPR',
      }),
    },
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const body = await response.json();

  return body;
};
