import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { Title, TITLE_SIZE } from '../componentUI/simple/Title';
import { Text, TEXT_SIZE } from '../componentUI/simple/Text';
import { Card } from './Card';
import { Button, LinkSecondButtonShowHide } from '../componentUI/simple/Button';
import { ResponseGetPhoto, ResponseGetPhotoList, Status } from './types';
import { LoadingStatus } from './LoadingStatus';
import { CancelOrder } from './CancelOrder';
import { Header } from './components/Header';
import { Error } from '../Error';
import {
  CardsWrapper,
  MainWrapper,
  PhotosWrapper,
  SubmitWrapper,
  TitleWithTooltipWrapper,
  TitleWrapper,
  UploadMainWrapper,
  UploadTitleWrapper,
  WrapperInstructionsBlock,
} from './styled';
import { PhotoInstructions } from '../components/PhotoInstructions';
import { Hr } from '../componentUI/simple/Hr';
import { COLOR_MAP } from '../componentUI/colorsMap';
import { useMatchMedia } from '../hooks/useMatchMedia';
import { CameraIcon } from '../common/icons/SvgInline/SvgIcons_4';
import { locale } from './locale';
import { fetchGetPhotoList, getAccessToken, postPhotos, rejectOrder } from './requestsNewAuth';

ReactModal.setAppElement('#root');

const SubmitButton = styled(Button)`
  width: 280px;
`;

export const UploadImg = () => {
  const { iconText, showButtonText, textTitlePhotoInstructions, linkToPhotoRules } = locale;

  const parsedUrl = new URL(window.location.href);
  let taskId = '';

  const taskIdMatch = parsedUrl.pathname.match(/\/([^&]+)/);

  if (taskIdMatch) {
    [, taskId] = taskIdMatch;
  } else {
    console.log('Task ID not found in the URL');
  }

  const admin = parsedUrl.searchParams.get('admin') || undefined;

  // const testToken =
  //   'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Imdna2FrVkpHWkhBbjlYRURBM19xQyJ9.eyJpc3MiOiJodHRwczovL2Rldi1pODh1dGJ5cC51cy5hdXRoMC5jb20vIiwic3ViIjoiTWQxOTF1Q1JmY3YxQ0hsZUtZY0NhMWJYWnI0cXh1bFJAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vYXV0aDAtand0LWF1dGhvcml6ZXIiLCJpYXQiOjE3MTU2MTc5MzIsImV4cCI6MTcxODIwOTkzMiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIiwiYXpwIjoiTWQxOTF1Q1JmY3YxQ0hsZUtZY0NhMWJYWnI0cXh1bFIifQ.CFYXz4T7gvApjPanTKFk8p5UCbjY2vQZ9eu_fObfxqqJPVXjDYM2_NFS03ydFoIWZN3a5oDi1ZCiOhKz2SHebDTnRMIPOXJPXaAYV_AYP_ccJRbE-K0aRgsoumP-BL5DUlsPWSOPxzT6RortLvw4MTWGBMHDJxIeEG4K9kUG5Mi_y8_dd2wBnFaKP6iNkKNmiotulnyhEZ9_6TgIdjQs1F6BmILuF7W3zsC5C-aA3KxfxpnisO_vuT6ACryoUct2baQJg-vqM5AK1J4ClEaFxiupr7py69tMzKzEsVt72VYzvOt1sp7UrC4HbpdHRH3_fCkEoJyzGyecwXognf074A';
  const [token, setToken] = useState('');

  const [requiredSubmit, setRequiredSubmit] = useState<Array<ResponseGetPhotoList>>([]);
  const [optionalSubmit, setOptionalSubmit] = useState<Array<ResponseGetPhotoList>>([]);
  const [statusRequest, setStatusRequest] = useState(Status.Loading);
  const [endScenarioMessage, setEndScenarioMessage] = useState('Everything has been uploaded successfully!');

  useEffect(() => {
    if (!taskId) {
      setStatusRequest(Status.Error);
      return;
    }

    getAccessToken()
      .then((token) => {
        setToken(token);
      })
      .catch(() => {
        setStatusRequest(Status.Error);
      });
  }, []);

  useEffect(() => {
    if (!taskId) {
      setStatusRequest(Status.Error);
      return;
    }

    if (token) {
      fetchGetPhotoList<ResponseGetPhoto>(token, taskId, admin)
        .then((res) => {
          if (!res.indexes) {
            setStatusRequest(Status.NoPhotos);
            return;
          }

          const required = res.indexes.filter((item) => item.hasOwnProperty('acceptable'));
          const optional = res.indexes.filter((item) => !item.hasOwnProperty('acceptable'));

          setRequiredSubmit(required);
          setOptionalSubmit(optional);

          setStatusRequest(Status.Done);
        })
        .catch(() => {
          setStatusRequest(Status.Error);
        });
    }
  }, [token]);

  const { isTablet } = useMatchMedia();

  const [validSubmitAllImg, setValidSubmitAllImg] = useState(true);

  const [statusRequestSubmitForm, setStatusRequestSubmitForm] = useState(Status.None);

  // upgrade photos
  const [requiredPhotosToSubmit, setRequiredPhotosToSubmit] = useState<
    Record<string, Array<{ name: string; value: string; is_unable_to_provide: boolean }>>
  >({});
  const [optionalPhotosToSubmit, setOptionalPhotosToSubmit] = useState<
    Record<string, Array<{ name: string; value: string; is_unable_to_provide: boolean }>>
  >({});
  const [lastIdSlot, setLastIdSlot] = useState<number | null>(null);

  // Function to revert the last change in requiredPhotosToSubmit for disable submit button
  const revertLastChange = () => {
    if (lastIdSlot !== null) {
      setRequiredPhotosToSubmit((prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[lastIdSlot];
        return updatedState;
      });
      setLastIdSlot(null); // Reset the last idSlot after reverting
    }
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (!validSubmitAllImg) {
      setValidSubmitAllImg(true);
    }
  }, [requiredPhotosToSubmit.length]);

  useEffect(() => {
    if (requiredSubmit.length) {
      const resetOrderLength = Object.values(requiredPhotosToSubmit).filter((item) =>
        item.some((item) => item.is_unable_to_provide),
      ).length;

      if (resetOrderLength === requiredSubmit.length) {
        setIsOpenModal(true);
      }
    }
  }, [requiredSubmit, requiredPhotosToSubmit]);

  const submitImageHandler = () => {
    if (!taskId) {
      setStatusRequest(Status.Error);
      return;
    }

    if (Object.values(requiredPhotosToSubmit).length !== requiredSubmit.length) {
      setValidSubmitAllImg(false);
      return;
    }

    setStatusRequestSubmitForm(Status.Loading);

    const bodyRequest = {
      id: taskId,
      photos: [...Object.values(requiredPhotosToSubmit), ...Object.values(optionalPhotosToSubmit)]
        .flat()
        .map(({ value, name, is_unable_to_provide }) => ({
          index: name,
          url: value,
          is_unable_to_provide,
        })),
    };

    postPhotos<{ response: string }>(token, bodyRequest, admin)
      .then((res) => {
        if (res.response === 'Photos were added') {
          setStatusRequestSubmitForm(Status.Done);
        }
      })
      .catch(() => {
        setStatusRequestSubmitForm(Status.Error);
      });
  };

  const goBackCallback = () => {
    revertLastChange();
    setIsOpenModal(false);
  };

  const rejectOrderCallback = () => {
    if (!taskId) {
      setStatusRequest(Status.Error);
      return;
    }

    setIsOpenModal(false);

    setStatusRequestSubmitForm(Status.Loading);

    rejectOrder(token, taskId)
      .then(() => {
        setStatusRequestSubmitForm(Status.Done);
        setEndScenarioMessage('Your order has been cancelled');
      })
      .catch(() => {
        setStatusRequestSubmitForm(Status.Error);
      });
  };

  if (statusRequest === Status.NoPhotos) {
    return (
      <Error
        errorTitle="No extra photos are currently needed to complete authentication of your item."
        errorMessage="Once extra photos are needed, we will send an email to you!"
      />
    );
  }

  if (statusRequest === Status.NoId) {
    console.log('noId');
    return (
      <CancelOrder
        isOpenModal={isOpenModal}
        goBackCallback={goBackCallback}
        rejectOrderCallback={rejectOrderCallback}
      />
    );
  }

  return (
    <>
      <CancelOrder
        isOpenModal={isOpenModal}
        goBackCallback={goBackCallback}
        rejectOrderCallback={rejectOrderCallback}
      />

      <MainWrapper>
        <Header isTablet={isTablet} />

        {statusRequestSubmitForm === Status.Done && (
          <TitleWrapper>
            <Title size={isTablet ? TITLE_SIZE.h5 : TITLE_SIZE.h4} style={{ textAlign: 'center' }}>
              {endScenarioMessage}
            </Title>
          </TitleWrapper>
        )}
        {statusRequestSubmitForm !== Status.Done && (
          <>
            <UploadMainWrapper>
              <UploadTitleWrapper>
                <Title size={isTablet ? TITLE_SIZE.h5 : TITLE_SIZE.h4}>Upload Additional Photos</Title>

                <WrapperInstructionsBlock>
                  <LinkSecondButtonShowHide
                    iconText={iconText}
                    text={showButtonText}
                    href={linkToPhotoRules}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CameraIcon />
                  </LinkSecondButtonShowHide>

                  <PhotoInstructions textTitle={textTitlePhotoInstructions} isSelect />
                </WrapperInstructionsBlock>
              </UploadTitleWrapper>
            </UploadMainWrapper>
            {statusRequest === Status.Done && Boolean(requiredSubmit.length) && (
              <PhotosWrapper>
                <LoadingStatus
                  statusRequest={statusRequest}
                  requiredSubmit={requiredSubmit}
                  optionalSubmit={optionalSubmit}
                />

                <>
                  <TitleWithTooltipWrapper>
                    <Title size={isTablet ? TITLE_SIZE.h5 : TITLE_SIZE.h4}>Required photos</Title>
                  </TitleWithTooltipWrapper>

                  <CardsWrapper>
                    {requiredSubmit.map((photo, index) => {
                      return (
                        <Card
                          key={photo.index_back_name}
                          {...photo}
                          idSlot={index}
                          setPhotosUploaded={setRequiredPhotosToSubmit}
                          isRequired
                          setLastIdSlot={setLastIdSlot}
                          examples={photo.image_samples}
                          // examples={test_image_samples}
                        />
                      );
                    })}
                  </CardsWrapper>
                </>
              </PhotosWrapper>
            )}
            {Boolean(requiredSubmit.length) && Boolean(optionalSubmit.length) && <Hr width="100%" />}
            {statusRequest === Status.Done && Boolean(optionalSubmit.length) && (
              <PhotosWrapper>
                <>
                  <TitleWithTooltipWrapper>
                    <Title size={isTablet ? TITLE_SIZE.h5 : TITLE_SIZE.h4}>Additional photos</Title>
                    <Text size={TEXT_SIZE.regular}>These photos are helpful but not obligatory</Text>
                  </TitleWithTooltipWrapper>

                  <CardsWrapper>
                    {optionalSubmit.map((photo, index) => (
                      <Card
                        key={photo.index_back_name}
                        idSlot={index}
                        {...photo}
                        setPhotosUploaded={setOptionalPhotosToSubmit}
                        setLastIdSlot={setLastIdSlot}
                        examples={photo.image_samples}
                      />
                    ))}
                  </CardsWrapper>
                </>
              </PhotosWrapper>
            )}
            {Boolean(optionalSubmit.length) && <Hr width="100%" />}
            <SubmitWrapper>
              {statusRequest !== Status.Error && (Boolean(requiredSubmit.length) || Boolean(optionalSubmit.length)) && (
                <>
                  <SubmitButton
                    isStretch
                    onClick={submitImageHandler}
                    disabled={
                      Object.values(requiredPhotosToSubmit).length !== requiredSubmit.length ||
                      statusRequestSubmitForm === Status.Loading
                    }
                    style={{ textTransform: 'uppercase' }}
                  >
                    Submit all images
                  </SubmitButton>
                </>
              )}
              {!validSubmitAllImg && (
                <Text colorText={COLOR_MAP.accent.red} style={{ textAlign: 'center' }}>
                  One or more of the needed photos have not been uploaded. Please upload all photos requested and press
                  “Submit” again.
                </Text>
              )}
              {statusRequestSubmitForm === Status.Error && (
                <Text colorText={COLOR_MAP.accent.red}>Loading error, please try again</Text>
              )}
            </SubmitWrapper>
            {statusRequest === Status.Error && (
              <Text colorText={COLOR_MAP.accent.red}>Loading error, please try again</Text>
            )}
          </>
        )}
      </MainWrapper>
    </>
  );
};
